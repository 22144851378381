//if is QingQi
import dayjs from "dayjs";

function isQingQi(pinfo) {
    if(isLightTruck(pinfo) || isVTruck(pinfo) || isJh6(pinfo) || isJk6(pinfo)) {
        return true
    }else{
        return false
    }
}

//判断是否为青汽轻卡
function isLightTruck(pinfo) {
    if(isThisSeries(pinfo, '虎v') || isJ6F(pinfo)) {
        return true
    }
    return false
}

function isJ6F(pinfo){
    return isThisSeries(pinfo, 'j6f')
}

function isVTruck(pinfo){
    if(isThisSeries(pinfo, '龙v') || isThisSeries(pinfo, '天v')
        || isThisSeries(pinfo, '悍v') || isThisSeries(pinfo, '途v')) {
        return true
    }
    return false
}

function isThisSeries(pinfo, series) {
    if (!pinfo) {
        return false
    }
    series = series.toLowerCase()
    if((pinfo.pdtSeriesText && pinfo.pdtSeriesText.toLowerCase().indexOf(series) > -1) ||
        (pinfo.productText && pinfo.productText.toLowerCase().indexOf(series) > -1) ||
        (pinfo.model && pinfo.model.toLowerCase().indexOf(series) > -1) ||
        (pinfo.cabTypeConfigText && pinfo.cabTypeConfigText.toLowerCase().indexOf(series) > -1)){
        return true
    }
    return false
}

function isJh6(pinfo){
    return isThisSeries(pinfo, 'jh6')
}

function isJk6(pinfo){
    return isThisSeries(pinfo, 'jk6')
}

function isJ6(pinfo){
    return isThisSeries(pinfo, 'j6')
}

function isJ7(pinfo){
    return isThisSeries(pinfo, 'j7')
}

export function getServicePrice(row, pinfo){
    if(row.insurePrice && row.insurePrice.priceService){
        return row.insurePrice.priceService
    }
    let priceService
    let price = getPrice(row)
    if (price) {
        if(isQingQi(pinfo)){
            priceService = price
        }else {
            priceService = (price * 0.95).toFixed(2)
        }
    }
    return priceService
}

export function getPrice(row){
    if(row.insurePrice && row.insurePrice.price){
        return row.insurePrice.price
    }
    let price
    if(row.priceEndUser===0 && !row.priceXichai){
        price = 0;
    }else {
        price = row.priceEndUser ? row.priceEndUser : row.priceXichai
    }
    if(!price){
        price = 0;
    }
    if (price) {
        price = (price * 1.13).toFixed(2)
    }
    return price
}

function calcOtherSupplierPrice(priceService){
    if(priceService) {
        return parseFloat(priceService).toFixed(2)
    }else{
        return '-'
    }
}

function calcOtherBrandPrice(priceService){
    if(priceService) {
        return (priceService * 0.75).toFixed(2)
    }else{
        return '-'
    }
}

function calcOtherSubFactoryPrice(priceService){
    if(priceService) {
        return (priceService*0.75*0.65).toFixed(2)
    }else{
        return '-'
    }
}

export function calcSupplierPrice(row, pinfo) {
    if(!pinfo || Object.keys(pinfo).length == 0){
        return '-'
    }

    if(row.insurePrice && row.insurePrice.priceSupplier){
        return row.insurePrice.priceSupplier
    }
    // let priceService = getServicePrice(row, pinfo)
    let priceBase= getPrice(row)
    if(!priceBase) {
        return '-'
    }

    let code = row.buyCode
    let price = getPrice(row)

    if(isJh6(pinfo) || isJk6(pinfo)){
        if(code.indexOf('5000010') > -1){
            return (priceBase*0.7+1000).toFixed(2)
        }
        if(code.indexOf('5000020') > -1){
            return (priceBase*0.75+500).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price >= 7000){
            return (priceBase*0.85).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price < 7000){
            return (priceBase*0.9).toFixed(2)
        }
        return (priceBase*0.9).toFixed(2)
    }
    if(isVTruck(pinfo)){
        if(code.indexOf('5000010') > -1){
            return (priceBase*0.67+1000).toFixed(2)
        }
        if(code.indexOf('5000020') > -1){
            return (priceBase*0.69+500).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price >= 7000){
            return (priceBase*0.85).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price < 7000){
            return (priceBase*0.9).toFixed(2)
        }
        return (priceBase*0.9).toFixed(2)
    }
    if(isLightTruck(pinfo)){
        if(code.indexOf('5000010') > -1){
            if(isJ6F(pinfo)) {
                return (priceBase * 0.82).toFixed(2)
            }else{
                return (priceBase*0.87).toFixed(2)
            }
        }
        if(code.indexOf('5000020') > -1){
            if(isJ6F(pinfo)) {
                return (priceBase * 0.82).toFixed(2)
            }else{
                return (priceBase*0.87).toFixed(2)
            }
        }
        if(code.indexOf('2800010') > -1 && price >= 7000){
            return (priceBase*0.92).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price < 7000){
            return (priceBase*0.92).toFixed(2)
        }
        return (priceBase*0.95).toFixed(2)
    }
    if(isJ6(pinfo)){
        if(code.indexOf('5000990') > -1){
            return (priceBase*0.85).toFixed(2)
        }
        if(code.indexOf('5000020') > -1){
            return (priceBase*0.85).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price >= 9000){
            return (priceBase*0.97).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price < 9000){
            return priceBase
        }
        if(price > 100){
            return (priceBase*0.97).toFixed(2)
        }
        if(price <= 100 && price > 20){
            return (priceBase*0.9).toFixed(2)
        }
        return (priceBase*0.9).toFixed(2)
    }
    if(isJ7(pinfo)){
        if(code.indexOf('5000990') > -1){
            return (priceBase*0.82).toFixed(2)
        }
        if(code.indexOf('5000020') > -1){
            return (priceBase*0.83).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price >= 9000){
            return (priceBase*0.97).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price < 9000){
            return priceBase
        }
        if(price > 100){
            return (priceBase*0.97).toFixed(2)
        }
        if(price <= 100 && price > 20){
            return (priceBase*0.9).toFixed(2)
        }
        return (priceBase*0.81).toFixed(2)
    }

    return calcOtherSupplierPrice(priceBase)
}

export function calcBrandPrice(row, pinfo) {
    if(row.insurePrice && row.insurePrice.priceBrand){
        return row.insurePrice.priceBrand
    }
    // let priceService = getServicePrice(row, pinfo)
    let priceBase = getPrice(row)
    if(!priceBase) {
        return '-'
    }

    let code = row.buyCode
    let price = getPrice(row)

    if(isJh6(pinfo) || isJk6(pinfo)){
        if(code.indexOf('5000010') > -1){
            return (priceBase*0.7*0.75).toFixed(2)
        }
        if(code.indexOf('5000020') > -1){
            return (priceBase*0.7*0.5).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price >= 7000){
            return '-'
        }
        if(code.indexOf('2800010') > -1 && price < 7000){
            return (priceBase*0.85).toFixed(2)
        }
        return (priceBase*0.9*0.9).toFixed(2)
    }
    if(isVTruck(pinfo)){
        if(code.indexOf('5000010') > -1){
            return (priceBase*0.67*0.85).toFixed(2)
        }
        if(code.indexOf('5000020') > -1){
            return (priceBase*0.69*0.85).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price >= 7000){
            return '-'
        }
        if(code.indexOf('2800010') > -1 && price < 7000){
            return (priceBase*0.85).toFixed(2)
        }
        return (priceBase*0.9*0.9).toFixed(2)
    }
    if(isLightTruck(pinfo)){
        if(code.indexOf('5000010') > -1){
            if(isJ6F(pinfo)) {
                return '?'
            }else{
                return '?'
            }
        }
        if(code.indexOf('5000020') > -1){
            if(isJ6F(pinfo)) {
                return '?'
            }else{
                return '?'
            }
        }
        if(code.indexOf('2800010') > -1 && price >= 7000){
            return '-'
        }
        if(code.indexOf('2800010') > -1 && price < 7000){
            return priceBase
        }
        return (priceBase*0.81*0.9).toFixed(2)
    }
    if(isJ6(pinfo)){
        if(code.indexOf('5000990') > -1){
            return (priceBase*0.82*0.75).toFixed(2)
        }
        if(code.indexOf('5000020') > -1){
            return (priceBase*0.83*0.5).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price >= 9000){
            return (priceBase*0.97*0.7).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price < 9000 && price >= 7000){
            return (priceBase*0.85).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price < 7000){
            return '-'
        }
        if(price > 100){
            return (priceBase*0.97*0.7).toFixed(2)
        }
        if(price <= 100 && price > 20){
            return '-'
        }
        return '-'
    }
    if(isJ7(pinfo)){
        return '-'
    }

    return calcOtherBrandPrice(priceBase)
}
export function calcSubFactoryPrice(row, pinfo) {
    if(row.insurePrice && row.insurePrice.priceSubFactory){
        return row.insurePrice.priceSubFactory
    }
    let price = getPrice(row)
    if(price < 100) {
        return '-'
    }
    // let priceService = getServicePrice(row, pinfo)
    let priceBase = getPrice(row)
    if(!priceBase) {
        return '-'
    }

    let code = row.buyCode

    if(isJh6(pinfo) || isJk6(pinfo)){
        if(code.indexOf('5000010') > -1){
            return (priceBase*0.7*0.65).toFixed(2)
        }
        if(code.indexOf('5000020') > -1){
            return (priceBase*0.75*0.35).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price >= 7000){
            return (priceBase*0.85*0.8).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price < 7000){
            return (priceBase*0.85).toFixed(2)
        }
        return (priceBase*0.81*0.8).toFixed(2)
    }
    if(isVTruck(pinfo)){
        if(code.indexOf('5000010') > -1){
            return (priceBase*0.65*0.75).toFixed(2)
        }
        if(code.indexOf('5000020') > -1){
            return (priceBase*0.69*0.75).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price >= 7000){
            return (priceBase*0.85*0.8).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price < 7000){
            return (priceBase*0.85).toFixed(2)
        }
        return (priceBase*0.9*0.8).toFixed(2)
    }
    if(isLightTruck(pinfo)){
        if(code.indexOf('5000010') > -1){
            if(isJ6F(pinfo)) {
                return '?'
            }else{
                return '?'
            }
        }
        if(code.indexOf('5000020') > -1){
            if(isJ6F(pinfo)) {
                return '?'
            }else{
                return '?'
            }
        }
        if(code.indexOf('2800010') > -1 && price >= 7000){
            return (priceBase*0.85*0.8).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price < 7000){
            return priceBase
        }
        return (priceBase*0.81*0.8).toFixed(2)
    }
    if(isJ6(pinfo)){
        if(code.indexOf('5000990') > -1){
            return (priceBase*0.82*0.6).toFixed(2)
        }
        if(code.indexOf('5000020') > -1){
            return (priceBase*0.83*0.4).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price >= 9000){
            return (priceBase*0.97*0.65).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price < 9000 && price >= 7000){
            return (priceBase*0.8).toFixed(2)
        }
        if(code.indexOf('2800010') > -1 && price < 7000){
            return '-'
        }
        if(price > 100){
            return (priceBase*0.97*0.6).toFixed(2)
        }
        if(price <= 100 && price > 20){
            return '-'
        }
        return '-'
    }
    if(isJ7(pinfo)){
        return '-'
    }

    return calcOtherSubFactoryPrice(priceBase)
}

function getFujiePriceJh6Jk6(code, pinfo) {
    let priceList = [
        { code: 'GP5000010-B83', model: '领航版', price: 60260 },
        { code: 'GP5000010-B83', model: '至尊版', price: 60260 },
        { code: 'GP5000010-B83', model: '领航版2.0', price: 65436 },
        { code: 'GP5000010-B83', model: '四季款', price: 65436 },
        { code: 'GP5000010-B83', model: '南方款', price: 65436 },
        { code: 'GP5000010-B83', model: '北方款', price: 65436 },
        { code: 'GP5000010-B83', model: '升级版', price: 59464 },
        { code: 'GP5000010-AB93', model: '卓越版', price: 59464 },
        { code: 'GP5000010-B93', model: '卓越版', price: 58402 },
        { code: 'GP5000010-JB93', model: '智慧版', price: 59555 },
        { code: 'GP5000010-H50', model: '卓越版', price: 63920 },
        { code: 'GP5000010-B47', model: '提升版', price: 53994 },
        { code: 'GP5000020-HB83H', model: '', price: 20184 },
        { code: 'GP5000020-B93', model: '', price: 20184 },
        { code: 'GP5000020-H50', model: '', price: 22932 },
        { code: 'GP5000020-B47', model: '', price: 18607 },
    ]
    let model = pinfo.cabTypeConfigText + pinfo.others
    let price = '-'
    let searchCode = code.toUpperCase()
    priceList.forEach(item => {
        item.code = item.code.toUpperCase()
        if(searchCode.indexOf(item.code) > -1){
            if(item.model.length > 0){
                if(model.indexOf(item.model) > -1) {
                    price = item.price
                }
            }else {
                price = item.price
            }
        }
    })
    return price
}

function getFujiePriceJ6(code, pinfo, vinInfo) {
    let offlineYear = Number(dayjs(vinInfo.offlineDate).format("YYYY"))
    let priceList = [
        //5000990E92R	南方&基础			59829
        { code: '5000990E92R', model: ['南方','基础'], part20: '', offLineYears: [], price: 59829 },
        //5000990E92R	绿通&基础			59829
        { code: '5000990E92R', model: ['绿通', '基础'], part20: '', offLineYears: [], price: 59829 },
        //5000990E78R	舒适	5000020&B27		57116
        { code: '5000990E78R', model: ['舒适'], part20: 'B27', offLineYears: [], price: 57116 },
        //5000990E90R	J6&南方版			62077
        { code: '5000990E90R', model: ['J6','南方'], part20: '', offLineYears: [], price: 62077 },
        //5000990E92R	质惠	5000020&D04		57116
        { code: '5000990E92R', model: ['质惠'], part20: 'D04', offLineYears: [], price: 57116 },
        //5000990E92R	质惠&北方	5000020&D04		58036
        { code: '5000990E92R', model: ['质惠','北方'], part20: 'D04', offLineYears: [], price: 58036 },
        //5000990E92R	质惠&2.0	5000020&LD18		58433
        { code: '5000990E92R', model: ['质惠','2.0'], part20: 'LD18', offLineYears: [], price: 58433 },
        //5000990E92R	质惠&2.0+	5000020&KD18		61556
        { code: '5000990E92R', model: ['质惠','2.0+'], part20: 'KD18', offLineYears: [], price: 61556 },
        //5000990E92R	质惠&2.0&北方	5000020&ND18		62077
        { code: '5000990E92R', model: ['质惠','2.0','北方'], part20: 'ND18', offLineYears: [], price: 62077 },
        //5000990E92R	质惠&绿通	5000020&DH12		62077
        { code: '5000990E92R', model: ['质惠','绿通'], part20: 'DH12', offLineYears: [], price: 62077 },
        //5000990E92R	质惠&2.0&北方	5000020&ND18		62077
        { code: '5000990E92R', model: ['质惠','2.0','北方'], part20: 'ND18', offLineYears: [], price: 62077 },
        //5000990-P856A/A	质惠&2.0	5000020&PH43		62077
        { code: '5000990-P856A/A', model: ['质惠','2.0'], part20: 'PH43', offLineYears: [], price: 62077 },
        //5000990E92R	质惠&2.0+	5000020&WH52		62077
        { code: '5000990E92R', model: ['质惠','2.0+'], part20: 'WH52', offLineYears: [], price: 62077 },
        //5000990E91U	领航		2016/2017/2018	60227
        { code: '5000990E91U', model: ['领航'], part20: '', offLineYears: [2016,2017,2018], price: 60227 },
        //5000990E90R	领航&北方	5000020&B27		60227
        { code: '5000990E90R', model: ['领航','北方'], part20: 'B27', offLineYears: [], price: 60227 },
        //5000990E90R	领航	5000020&D04		60227
        { code: '5000990E90R', model: ['领航'], part20: 'D04', offLineYears: [], price: 60227 },
        //5000990E90R	J6&领航&2.0			62077
        { code: '5000990E90R', model: ['J6','领航','2.0'], part20: '', offLineYears: [], price: 62077 },
        //5000990E90R	领航	5000020&TH43		62077
        { code: '5000990E90R', model: ['领航'], part20: 'TH43', offLineYears: [], price: 62077 },
        //5000990E90R	四季	5000020&D04		61556
        { code: '5000990E90R', model: ['四季'], part20: 'D04', offLineYears: [], price: 61556 },
        //5000990EB44	自卸&平顶		2016/2017/2018	46115
        { code: '5000990EB44', model: ['自卸','平顶'], part20: '', offLineYears: [2016,2017,2018], price: 46115 },
        //5000990EB71	半浮&平顶			46115
        { code: '5000990EB71', model: ['半浮','平顶'], part20: '', offLineYears: [], price: 46115 },
        //5000990EA86	质惠			46115
        { code: '5000990EA86', model: ['质惠'], part20: '', offLineYears: [], price: 46115 },
        //5000990-H39	领航&绿通			51535
        { code: '5000990-H39', model: ['领航','绿通'], part20: '', offLineYears: [], price: 51535 },
        //5000990-H10	精英			51535
        { code: '5000990-H10', model: ['精英'], part20: '', offLineYears: [], price: 51535 },
        //5000990-22P/H	尊享			51535
        { code: '5000990-22P/H', model: ['尊享'], part20: '', offLineYears: [], price: 51535 },
        //5000990EB35V	山区			53533
        { code: '5000990EB35V', model: ['山区'], part20: '', offLineYears: [], price: 53533 },
        //5000990EB35V	山区&2.0			53533
        { code: '5000990EB35V', model: ['山区','2.0'], part20: '', offLineYears: [], price: 53533 },
        //5000990E84V	山区	5000020&AH13		57388
        { code: '5000990E84V', model: ['山区'], part20: 'AH13', offLineYears: [], price: 57388 },
        //5000990E84V	山区			51912
        { code: '5000990E84V', model: ['山区'], part20: '', offLineYears: [], price: 51912 },
        //5000990-H46	山区			55715
        { code: '5000990-H46', model: ['山区'], part20: '', offLineYears: [], price: 55715 },
        //5000990-H12	山区			59828
        { code: '5000990-H12', model: ['山区'], part20: '', offLineYears: [], price: 59828 },
        //5000020CH12	山区			19734
        { code: '5000020CH12', model: ['山区'], part20: '', offLineYears: [], price: 19734 },
        //5000020-H10/B	精英			19734
        { code: '5000020-H10/B', model: ['精英'], part20: '', offLineYears: [], price: 19734 },
        //5000020-H39(H)	领航&绿通			19734
        { code: '5000020-H39(H)', model: ['领航','绿通'], part20: '', offLineYears: [], price: 19734 },
        //5000020-21P/A	尊享			19734
        { code: '5000020-21P/A', model: ['尊享'], part20: '', offLineYears: [], price: 19734 },
        //5000020-22P/A	尊享			19734
        { code: '5000020-22P/A', model: ['尊享'], part20: '', offLineYears: [], price: 19734 },
        //5000020-PH20/D	尊享			19734
        { code: '5000020-PH20/D', model: ['尊享'], part20: '', offLineYears: [], price: 19734 },
        //5000020-Y2174/B	尊享			19734
        { code: '5000020-Y2174/B', model: ['尊享'], part20: '', offLineYears: [], price: 19734 },
        //5000020EB71	半浮&平顶			16497
        { code: '5000020EB71', model: ['半浮','平顶'], part20: '', offLineYears: [], price: 16497 },
        //5000020EA86	质惠		2016/2017/2018	16497
        { code: '5000020EA86', model: ['质惠'], part20: '', offLineYears: [2016,2017,2018], price: 16497 },
        //5000020EB44	窄体&自卸			16497
        { code: '5000020EB44', model: ['窄体','自卸'], part20: '', offLineYears: [], price: 16497 },
        //5000020KD18	质惠&2.0			19734
        { code: '5000020KD18', model: ['质惠','2.0'], part20: '', offLineYears: [], price: 19734 },
        //5000020BD04	南方&基本			19734
        { code: '5000020BD04', model: ['南方','基本'], part20: '', offLineYears: [], price: 19734 },
        //5000020FD04	北方			19734
        { code: '5000020FD04', model: ['北方'], part20: '', offLineYears: [], price: 19734 },
        //5000020AB82	领航			19734
        { code: '5000020AB82', model: ['领航'], part20: '', offLineYears: [], price: 19734 },
        //5000020AH46	山区			18862
        { code: '5000020AH46', model: ['山区'], part20: '', offLineYears: [], price: 18862 },
        //5000020EB26	山区			18862
        { code: '5000020EB26', model: ['山区'], part20: '', offLineYears: [], price: 18862 },
        //5000020EB35	山区			18515
        { code: '5000020EB35', model: ['山区'], part20: '', offLineYears: [], price: 18515 },
        //5000020AH13	山区			18515
        { code: '5000020AH13', model: ['山区'], part20: '', offLineYears: [], price: 18515 },
        //5000020ND13	高顶		2019/2020/2021/2022/2023	19734
        { code: '5000020ND13', model: ['高顶'], part20: '', offLineYears: [2019,2020,2021,2022,2023], price: 19734 },
        //5000020-45N	高顶		2019/2020/2021/2022/2023	19734
        { code: '5000020-45N', model: ['高顶'], part20: '', offLineYears: [2019,2020,2021,2022,2023], price: 19734 },
        //5000020YH04	高顶		2019/2020/2021/2022/2023	19734
        { code: '5000020YH04', model: ['高顶'], part20: '', offLineYears: [2019,2020,2021,2022,2023], price: 19734 },
        //5000020BPH43	高顶		2019/2020/2021/2022/2023	19734
        { code: '5000020BPH43', model: ['高顶'], part20: '', offLineYears: [2019,2020,2021,2022,2023], price: 19734 },
        //5000020BPH43	高顶		2019/2020/2021/2022/2023	19734
        { code: '5000020BPH43', model: ['高顶'], part20: '', offLineYears: [2019,2020,2021,2022,2023], price: 19734 },
        //5000020LD18	高顶		2019/2020/2021/2022/2023	19734
        { code: '5000020LD18', model: ['高顶'], part20: '', offLineYears: [2019,2020,2021,2022,2023], price: 19734 },
        //5000020-834A	高顶		2019/2020/2021/2022/2023	19734
        { code: '5000020-834A', model: ['高顶'], part20: '', offLineYears: [2019,2020,2021,2022,2023], price: 19734 },
        //5000020TH43LH	高顶		2019/2020/2021/2022/2023	19734
        { code: '5000020TH43LH', model: ['高顶'], part20: '', offLineYears: [2019,2020,2021,2022,2023], price: 19734 },
        //5000020WH12	高顶		2019/2020/2021/2022/2023	19734
        { code: '5000020WH12', model: ['高顶'], part20: '', offLineYears: [2019,2020,2021,2022,2023], price: 19734 },
        //5000020TH48	高顶		2019/2020/2021/2022/2023	19734
        { code: '5000020TH48', model: ['高顶'], part20: '', offLineYears: [2019,2020,2021,2022,2023], price: 19734 },
        //5000020QH48	高顶		2019/2020/2021/2022/2023	19734
        { code: '5000020QH48', model: ['高顶'], part20: '', offLineYears: [2019,2020,2021,2022,2023], price: 19734 },
        //5000020MH04	高顶		2019/2020/2021/2022/2023	19734
        { code: '5000020MH04', model: ['高顶'], part20: '', offLineYears: [2019,2020,2021,2022,2023], price: 19734 },
        //5000020AH45J	平顶		2019/2020/2021/2022/2023	18862
        { code: '5000020AH45J', model: ['平顶'], part20: '', offLineYears: [2019,2020,2021,2022,2023], price: 18862 },
        //5000020AH45	平顶		2019/2020/2021/2022/2023	18862
        { code: '5000020AH45', model: ['平顶'], part20: '', offLineYears: [2019,2020,2021,2022,2023], price: 18862 },
        //5000020-51N	平顶		2019/2020/2021/2022/2023	18862
        { code: '5000020-51N', model: ['平顶'], part20: '', offLineYears: [2019,2020,2021,2022,2023], price: 18862 },
    ]
    let model = pinfo.cabTypeConfigText + pinfo.others
    model = model.toUpperCase()
    let price = '-'
    let searchCode = code.toUpperCase()
    for(let i=0; i < priceList.length; i++) {
        let item = priceList[i]
        item.code = item.code.toUpperCase()
        if(searchCode.indexOf(item.code) > -1){
            if(item.model.filter(m => model.indexOf(m) > -1).length >= item.model.length) {
                let setPrice = true
                if(item.part20) {
                    if(pinfo.parts5000020.filter(p => p.toUpperCase().indexOf(item.part20) > -1).length <= 0) {
                        setPrice = false
                    }
                }
                if(item.offLineYears.length > 0) {
                    if(offlineYear == 0) {
                        setPrice = false
                    } else {
                        if (item.offLineYears.indexOf(offlineYear) <= -1) {
                            setPrice = false
                        }
                    }
                }
                if(setPrice) {
                    price = item.price
                    break
                }
            }
        }
    }
    return price
}

export function calcFujiePrice(row, pinfo, vinInfo) {
    let priceFujie = '-'

    let code = row.buyCode

    if(isJh6(pinfo) || isJk6(pinfo)){
        if(code.indexOf('5000010') > -1){
            priceFujie = getFujiePriceJh6Jk6(code, pinfo)
        }
        if(code.indexOf('5000020') > -1){
            priceFujie = getFujiePriceJh6Jk6(code, pinfo)
            if(priceFujie != '-') {
                priceFujie = (priceFujie*1.15).toFixed(2)
            }
        }
        return priceFujie
    }
    if(isVTruck(pinfo)){
        return priceFujie
    }
    if(isLightTruck(pinfo)){
        return priceFujie
    }
    if(isJ6(pinfo)){
        if(code.indexOf('5000990') > -1){
            priceFujie = getFujiePriceJ6(code, pinfo, vinInfo)
            if(priceFujie != '-') {
                priceFujie = (priceFujie * 1.05).toFixed(2)
            }
        }
        if(code.indexOf('5000020') > -1){
            priceFujie = getFujiePriceJ6(code, pinfo, vinInfo)
            if(priceFujie != '-') {
                priceFujie = (priceFujie * 1.15).toFixed(2)
            }
        }
        return priceFujie
    }
    if(isJ7(pinfo)){
        return priceFujie
    }

    return priceFujie
}
